<template lang="pug">
div.settings-change
  div.settings-change__settings-panel.panel(v-if='browserIsChrome')
    v-icon.settings-change__settings-panel-close.icon(
      v-if='showCloseIcon'
      icon='XIcon'
      size='24'
      @click='closeSettingsModal'
    )
    div.settings-change__settings-panel-title Настройка камеры и микрофона
    video.settings-change__preview(
      v-show="false"
      ref='defaultPreviewVideo'
      muted
      autoplay
      playsinline
      poster='@/images/account_circle.png'
    )
    video.settings-change__preview(
      v-show="false"
      ref='blurPreviewVideo'
      muted
      autoplay
      playsinline
      poster='@/images/account_circle.png'
    )
    video.settings-change__preview(
      v-show="true"
      ref='canvasPreviewVideo'
      muted
      autoplay
      playsinline
      poster='@/images/account_circle.png'
    )
    div.settings-change__switch-wrapper
      span Размыть фон
      div.settings-change__switch(:class="{on: getUseBgEffects}" @click="toggleVirtualBg")
        div.settings-change__switch-handle 
      div.settings-change__settings-quality(v-if='getUseBgEffects')
        div.settings-change__select-container
          select.settings-change__select(
            v-model='quality'
            value="Низкое качество"
            @change='setQuality()'
          )
            template(v-for='item in videoQuality')
              option(:value='item.height') {{ item.name }}
    //- button(@click="resumeVirtualBgStream" v-show="true") play
    //- button(@click="pauseVirtualBgStream" v-show="true") stop
    //- button(@click="changeMediaStream('default')" v-show="true") default
    //- button(@click="changeMediaStream('blur')" v-show="true") blur
    

    div.settings-change__settings-panel-camera(v-if='videoDevices.length')
      div.settings-change__select-title Камера
      div.settings-change__select-container
        select.settings-change__select(
          v-model='selectedDevicesId.currentVideoId'
          @change='setPreviewVideo'
        )
          template(v-for='item in videoDevices')
            option(:value='item.deviceId') {{ item.label }}

    div.settings-change__settings-panel-mic(v-if='audioDevices.length')
      div.settings-change__select-title Микрофон
      div.settings-change__select-container
        select.settings-change__select(
          v-model='selectedDevicesId.currentAudioId'
        )
          template(v-for='item in audioDevices')
            option(:value='item.deviceId') {{ item.label }}
    div.settings-change__settings-panel-button
      v-button(@click.native='onDeviceChange' block disabled ref='submitBtn') {{ isModal ? 'Сохранить' : 'Войти на мероприятие' }}
  div.not-chrome.panel(v-else)
    h1 
      span Пожалуйста, для корректной работы стриминговой студии, используйте
      br
      a(href='https://www.google.nr/intl/ru/chrome/' title='Скачать браузер') Google Chrome
</template>

<script>
import Vue from 'vue'
import VButton from '../components/v-button.vue'
import router from '../router'
import { mapGetters, mapActions } from 'vuex'
// import VIDEO from '@/constants/video'
import { detect } from 'detect-browser'

export default {
  name: 'VSettingsChange',
  components: {
    VButton,
  },
  props: {
    isModal: { type: Boolean, default: false },
    showCloseIcon: { type: Boolean, default: true },
  },
  data: () => ({
    selectedDevicesId: {
      currentAudioId: null,
      currentVideoId: null,
    },
    quality: this?.getVirtualBg?.holstOptions.height || 240,
  }),
  computed: {
    ...mapGetters('devices', [
      'getCurrentDevicesId',
      'getAudioDevices',
      'getVideoDevices',
    ]),
    ...mapGetters('video', [
      'getStream',
      'getBgType',
      'getCameraStream',
      'getCanvasStream',
      'getOutputStream',
      'getUseBgEffects',
      'getVirtualBg',
    ]),
    videoQuality() {
      return [
        { name: 'Высокое качество', height: 720 },
        { name: 'Среднее качество', height: 360 },
        { name: 'Низкое качество', height: 240 },
      ]
    },
    audioDevices: {
      get() {
        return this.getAudioDevices
      },
    },
    videoDevices: {
      get() {
        return this.getVideoDevices
      },
    },
    showDisplay() {
      return this.$route?.query?.showOriginalDisplay
    },
    browserIsChrome() {
      console.log(detect().name)
      return (
        detect().name === 'chrome' ||
        detect().name === 'safari' ||
        detect().name === 'ios' ||
        detect().name === 'crios'
      )
    },
  },
  async created() {
    await this.getDevices()
    await this.getAccessToDevices()
    // this.setPreviewVideo()
  },
  async mounted() {
    await navigator.permissions.query({ name: 'camera' }).then((res) => {
      res.onchange = () => {
        return this.getDevices()
      }
    })
    await navigator.permissions.query({ name: 'microphone' }).then((res) => {
      res.onchange = () => {
        return this.getDevices()
      }
    })
    // if (this.getCanvasStream) {
    //   this.quality = this.getVirtualBg.holstOptions.height
    // }
    if (this?.getVirtualBg?.holstOptions.height) {
      this.quality = this?.getVirtualBg?.holstOptions.height
    }
    this.setPreviewVideo()
    this.changeVideoBg(this.getBgType)
    this.setQuality()
  },
  methods: {
    ...mapActions('devices', [
      'requestDevices',
      'getAccessToDevices',
      'setCurrentDevicesId',
    ]),
    ...mapActions('video', [
      'changeVideoBg',
      'startVirtualBg',
      'resumeVirtualBgStream',
      'pauseVirtualBgStream',
      'changeMediaStream',
      'setUseBgEffects',
      'changeHolstOption',
    ]),
    async setQuality() {
      await this.setPreviewVideo()
      this.changeHolstOption(this.quality)
      if (this.getCanvasStream) {
        this.changeVideoBg('blur')
      }
    },
    async toggleVirtualBg() {
      if (this.getBgType === 'default' || this.getBgType === undefined) {
        if (this.getCanvasStream) {
          await this.resumeVirtualBgStream()
        }
        // await this.resumeVirtualBgStream()
        if (!this.getUseBgEffects) {
          this.setUseBgEffects(true)
          await this.setPreviewVideo()
        }
        this.changeVideoBg('blur')
        // this.pauseVirtualBgStream
      } else {
        this.changeVideoBg('default')
        if (this.getUseBgEffects) {
          this.setUseBgEffects(false)
          await this.setPreviewVideo()
        }
        this.pauseVirtualBgStream()
      }
    },
    closeSettingsModal() {
      this.$store.commit('UPDATE_MODAL_SETTINGS_VISIBLE', false)
    },
    async onDeviceChange() {
      if (!this.$store.state.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext
        const audioContext = new AudioContext()
        this.$store.commit('UPDATE_AUDIO_CONTEXT', audioContext)
      }

      this.$store.commit('UPDATE_MODAL_SETTINGS_VISIBLE', false)

      this.setCurrentDevicesId(this.selectedDevicesId)

      setTimeout(() => {
        this.getAccessToDevices()
      }, 0)
      // if (this.getBgType === 'default') {
      //   this.pauseVirtualBgStream()
      // }
      this.$emit('on-settings-change')

      if (!this.isModal) {
        router.push({
          path: '/',
          query: {
            workspace: this.$store.state.query.workspace,
            key: this.$store.state.query.key,
          },
        })
      }
    },
    async getDevices() {
      return this.requestDevices().then(() => {
        const currentDevicesId = this.getCurrentDevicesId

        if (
          !currentDevicesId.currentAudioId &&
          !currentDevicesId.currentVideoId
        ) {
          this.setCurrentDevicesId({
            currentAudioId: this.audioDevices[0]?.deviceId,
            currentVideoId: this.videoDevices[0]?.deviceId,
          })

          this.selectedDevicesId = {
            currentAudioId: this.audioDevices[0]?.deviceId,
            currentVideoId: this.videoDevices[0]?.deviceId,
          }
        } else {
          this.selectedDevicesId = { ...currentDevicesId }
        }
      })
    },
    async setPreviewVideo() {
      this.$refs.submitBtn.$el.disabled = true
      const isMobile =
        Vue.prototype.$isMobile && window.innerHeight > window.innerWidth
      let constraints = {
        audio: true,
        video: {
          deviceId: this.selectedDevicesId.currentVideoId,
          aspectRatio: { ideal: 16 / 9 },
          height: { ideal: this.getBgType === 'blur' ? this.quality : 720 },
        },
      }
      if (isMobile) {
        constraints.video.height = { ideal: 640 }
        constraints.video.width = { ideal: 360 }
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(async (stream) => {
          if (this.getUseBgEffects && this.getBgType !== 'default') {
            await this.startVirtualBg(stream)
            this.$refs.blurPreviewVideo.srcObject = this.getCameraStream
            this.$refs.canvasPreviewVideo.srcObject = this.getCanvasStream
          } else {
            this.$refs.canvasPreviewVideo.srcObject = stream
          }
          this.$refs.submitBtn.$el.disabled = false
        })
        .catch((e) => {
          console.log('get user media error: ', e)
        })
    },
  },
}
</script>

<style lang="scss">
.settings-change {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  &__settings-panel-close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 34px;
    width: 34px;
    cursor: pointer;
  }

  &__settings-panel {
    position: relative;
    // height: 400px;
    width: 650px;
    padding: 52px 62px;

    &-title {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 28px;
      text-align: center;
      color: #5e5873;
    }
  }
  &__settings-quality {
    width: 100%;
  }
  &__settings-panel-camera {
    margin-bottom: 16px;
  }

  &__settings-panel-mic {
    width: 520px;
  }

  &__settings-panel-button {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding: 10px 0;
    width: 100%;
  }

  &__select-title {
    margin-bottom: 6px;
    font-size: 12px;
    color: #6e6b7b;
  }

  &__select-container {
    position: relative;
  }

  &__select {
    appearance: none;
    position: relative;
    display: block;
    width: 100%;
    padding: 7px 12px;
    font-size: 12px;
    color: #b9b9c3;
    border: 1px solid #d8d6de;
    border-radius: $border-radius;
  }

  &__preview {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }

  @media (max-width: 800px) {
    &__settings-panel {
      width: 90%;
      padding: 20px 30px;

      &-title {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    &__settings-panel-mic {
      width: 100%;
    }
    &__preview {
      max-height: 35vh;
    }
  }
  .not-chrome {
    padding: 3rem 1rem;
    h1 {
      text-align: center;
      line-height: 4rem;
    }
  }
  .settings-change__switch-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 32px;
    span {
      min-width: 110px;
    }
  }
  .settings-change__switch {
    min-width: 42px;
    width: 42px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #dee2e6;
    cursor: pointer;
  }
  .settings-change__switch.on {
    background-color: #28c76f;
    justify-content: end;
  }
  .settings-change__switch-handle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.2);
  }
}
</style>
